import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import StopForm from '../../features/new-do/outside-use/stop-form';
import { makeSingleTelStop } from '../../features/new-do/helpers';
import { refreshAssignedLoadsRequest } from '../../features/drivers-card/actions';
import { getLoadDetailsRequest } from '../../features/dispatch-details-new/load/actions';
import { getOrderDetailsRequest } from '../../features/dispatch-details-new/order/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const withStopForm = compose(
  connect(
    null,
    {
      openModal,
      closeModal,
      openLoader,
      closeLoader,
      refreshAssignedLoadsRequest,
      getDispatchDetailsLoadRequest: getLoadDetailsRequest,
      getDispatchDetailsOrderRequest: getOrderDetailsRequest,
    },
  ),
  withHandlers({
    saveStopForm: (props: Object) => async (payload: Object) => {
      try {
        const {
          fromPage,
          loadGuid,
          loadType,
          closeModal,
          openLoader,
          closeLoader,
          getLoadDetailsRequest,
          refreshAssignedLoadsRequest,
          getDispatchDetailsLoadRequest,
          getDispatchDetailsOrderRequest,
        } = props;

        openLoader();
        const reqData = makeSingleTelStop(payload, loadGuid);
        const options = { data: reqData };
        const endpoint = G.ifElse(
          G.isLoadTypeClo(loadType),
          endpointsMap.cloEventUpdateSingle,
          endpointsMap.telEventUpdateSingle,
        );
        let res = await sendRequest('put', endpoint, options);

        if (G.isOptimisticLocking(res.status)) {
          const reqData2 = G.incEventLocationContactVersions(reqData);

          res = await sendRequest('put', endpoint, { data: reqData2 });
        }

        if (
          G.isResponseSuccess(
            res.status,
            res.data,
            G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
          )
        ) {
          if (G.isPageDispatchDetailsNewLoad(fromPage)) {
            G.callFunctionWithArgs(getDispatchDetailsLoadRequest, loadGuid);
            G.callFunction(closeModal);
          }

          if (G.isPageDispatchDetailsNewOrder(fromPage)) {
            G.callFunctionWithArgs(getDispatchDetailsOrderRequest, loadGuid);
            G.callFunction(closeModal);
          }

          if (R.equals(fromPage, 'driversCard')) {
            G.callFunction(refreshAssignedLoadsRequest);
            G.callFunctionTwice(closeModal);
          }

          if (G.isPageDispatchBoardNew(fromPage)) {
            const payload = {
              expandDetails: false,
              [GC.FIELD_GUID]: loadGuid,
              callCloEventReferences: true,
            };
            G.callFunctionWithArgs(getLoadDetailsRequest, payload);
            G.callFunction(closeModal);
          }
        } else {
          G.handleFailResponseSimple(res);
        }
        closeLoader();
      } catch (error) {
        props.closeLoader();
        G.catchSendRequestSimple(error, 'saveStopForm');
      }
    },
  }),
  withHandlers({
    handleChangeStop: (props: Object) => ({ event, branchGuid, referenceTypes }: Object) => {
      const { openModal, saveStopForm } = props;

      const comments = R.path(['location', GC.FIELD_COMMENTS], event);

      const initialValues = {
        ...event.location,
        ...R.pathOr({}, ['location', 'contacts', 0], event),
        ...G.formatEventDates(event),
        comments,
        [GC.FIELD_LOCATION_TYPE]: R.path(
          [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
          event,
        ),
      };
      const modalContent = (
        <StopForm
          {...props}
          branchGuid={branchGuid}
          submitHandler={saveStopForm}
          initialValues={initialValues}
          referenceTypes={referenceTypes}
          configsNamesArray={[GC.TEMPLATES_LOCATION_TYPE]}
        />
      );
      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          width: 700,
          maxHeight: '80vh',
          overflow: 'hidden',
        },
      };
      openModal(modal);
    },
  }),
  pure,
);

export default withStopForm;
