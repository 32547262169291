import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
//////////////////////////////////////////////////

export const withLocationCustomChangeHandler = compose(
  withHandlers({
    handleCustomChange: ({ values, setValues, setStoredValues }: Object) => ({ target }: Object) => {
      const { id, value } = target;

      const newValues = R.assoc(id, value, values);

      setStoredValues(newValues, () => setValues(newValues));
    },
  }),
);
