import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withConnectModalAndLoaderActions } from '../../hocs';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// forms
import ContainerForm from '../forms/container-form';
//////////////////////////////////////////////////

export const withUpdateContainerForm = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    getContainerRequest: (props: Object) => async (item: Object) => {
      const { openLoader, closeLoader } = props;

      const { guid } = item;

      openLoader();

      const res = await sendRequest('get', endpointsMap.routeContainerByGuid(guid));

      const { data, status } = res;

      closeLoader();

      if (G.isResponseSuccess(status)) return data;

      return null;
    },
    updateContainerRequest: (props: Object) => async (values: Object, item: Object) => {
      const { closeModal, updateContainerCallback } = props;

      const options = { data: values };

      const res = await sendRequest('put', endpointsMap.routeContainer, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        G.callFunctionWithArgs(updateContainerCallback, { item, container: data });

        G.showToastrMessageFromLocale('success', 'messages:success:update');

        closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  withHandlers({
    handleUpdateContainer: (props: Object) => async (item: Object) => {
      const {
        openModal,
        closeModal,
        getContainerRequest,
        updateContainerRequest,
      } = props;

      const searchedValues = await getContainerRequest(item);

      const component = (
        <ContainerForm
          closeModal={closeModal}
          searchedValues={searchedValues}
          containerTypes={G.getAmousContainerTypeListFromWindow()}
          submitAction={(values: Object) => updateContainerRequest(values, item)}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'auto',
          height: 'auto',
          maxHeight: '90vh',
          title: G.getWindowLocale('titles:edit-container', 'Edit Container'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);
