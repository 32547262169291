import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import AppointmentsForm from '../forms/appointments-form';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const fieldsToPickUpdateAppts = [GC.FIELD_STOP_NUMBER, ...GC.GROUPED_FIELDS.APPOINTMENTS_PICK_ARR];

const withAppointmentsForm = compose(
  connect(null, { openLoader, closeLoader, openModal, closeModal }),
  withHandlers({
    saveAppointments: (props: Object) => async (payload: Object) => {
      try {
        const { closeModal, openLoader, closeLoader } = props;
        openLoader();
        const reqData = R.pick(fieldsToPickUpdateAppts, payload);
        const options = {
          data: reqData,
        };
        const res = await sendRequest('put', endpointsMap.telEventAppointment, options);
        const { data, status } = res;
        if (G.isResponseSuccess(
          status,
          data,
          G.getWindowLocale('messages:success:200-201', 'The request has succeeded'),
        )) {
          G.callFunctionTwice(closeModal);
        } else {
          G.handleFailResponseSimple(res);
        }
        closeLoader();
      } catch (error) {
        props.closeLoader();
        G.catchSendRequestSimple(error, 'saveAppointments');
      }
    },
  }),
  withHandlers({
    handleChangeAppointment: (props: Object) => (event: Object) => {
      const { openModal, saveAppointments } = props;

      const loadType = GC.LOAD_TYPE_TEL;
      const loadGuid = R.prop(GC.FIELD_TEL_GUID, event);

      const initialValues = {
        ...event,
        loadType,
        loadGuid,
      };

      const submitAction = (data: Object) => saveAppointments(data);

      const modalContent = (
        <AppointmentsForm
          {...props}
          submitAction={submitAction}
          initialValues={initialValues}
        />
      );

      const modal = {
        p: '0px',
        component: modalContent,
        options: {
          title: '',
          height: 'auto',
          width: 'min-content',
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export default withAppointmentsForm;
